@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: 0 $tokenSpacingSmall2X;

  background-color: $tokenColorsBrandTertiaryDefault;
  border-radius: $tokenSpacingLarge2X;
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingLarge1X;
  margin: var(--margin);
  margin-bottom: $tokenSpacingLarge6X;
  padding: $tokenSpacingLarge1X;
  padding-bottom: $tokenSpacingLarge3X;

  @media (--breakpointMediumFrom) {
    --margin: 0 calc((-1 * 10%));

    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
    padding: $tokenSpacingLarge5X $tokenSpacingLarge1X;
    padding-bottom: calc($tokenSpacingLarge5X * 2);
  }

  @media (--breakpointLargeFrom) {
    align-items: center;
  }

  & .titleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingDefault;
    margin-bottom: $tokenSpacingSmall2X;
    padding: 0 $tokenSpacingLarge1X;

    @media (--breakpointLargeFrom) {
      gap: $tokenSpacingSmall3X;
      margin-bottom: 0;
      padding: 0;
    }

    & h2 {
      --margin: 0 $tokenSpacingLarge3X;

      margin: var(--margin);

      @media (--breakpointLargeFrom) {
        --margin: 0 0;
      }
    }

    & h1 {
      color: $tokenColorsGreyscaleDark2X;
      font-size: $tokenFontSizesLarge1X;
      font-weight: $tokenFontWeightsRegular;
      text-align: center;

      @media (--breakpointMediumFrom) {
        font-size: $tokenFontSizesLarge2X;
      }
    }
  }

  & .mobileSearchWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingDefault;
    justify-content: center;
  }
}
