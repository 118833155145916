@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: $tokenSpacingLarge3X $tokenSpacingSmall2X;
  --padding: 0;

  background-color: $tokenColorsInteractionDisabledBackground;
  border-radius: $tokenSpacingLarge2X;
  column-gap: $tokenSpacingLarge3X;
  display: grid;
  grid-template-columns: 1fr;
  margin: var(--margin);
  margin-bottom: $tokenSpacingLarge6X;
  padding: var(--padding);

  @media (--breakpointMediumFrom) {
    --margin: $tokenSpacingLarge6X calc(-1 * 12.5%);
    --padding: $tokenSpacingLarge5X $tokenSpacingLarge3X;

    grid-template-columns: calc($tokenSpacingLarge3X * 7) 1fr;
    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  & img {
    border-top-left-radius: $tokenSpacingLarge2X;
    border-top-right-radius: $tokenSpacingLarge2X;

    @media (--breakpointMediumFrom) {
      border-radius: $tokenSpacingLarge2X;
    }
  }

  & .rootContainer {
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingLarge2X;
    padding: $tokenSpacingLarge1X;
    padding-bottom: $tokenSpacingLarge3X;

    @media (--breakpointLargeFrom) {
      padding: 0;
    }

    & .content {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: $tokenSpacingLarge2X;

      @media (--breakpointLargeFrom) {
        column-gap: $tokenSpacingLarge2X;
        grid-template-columns: 1fr 1fr;
      }

      & .kennels svg {
        color: $tokenColorsNegativeDefault;
      }

      & .madpaws svg {
        color: $tokenColorsBrandDefault;
      }

      & .details {
        display: flex;
        flex-direction: column;
        gap: $tokenSpacingDefault;

        & .title {
          font-size: $tokenFontSizesLarge2X;
          font-weight: $tokenFontWeightsBold;
        }

        & .detailRaw {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: $tokenSpacingDefault;

          & svg {
            max-width: unset;
          }
        }
      }
    }
  }
}
