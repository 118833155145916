@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: 0 $tokenSpacingSmall2X;

  display: grid;
  grid-template-columns: 1fr;
  margin: var(--margin);
  margin-bottom: $tokenSpacingLarge6X;

  @media (--breakpointMediumFrom) {
    --margin: 0 calc((-1 * 10%));

    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  @media (--breakpointLargeFrom) {
    align-items: center;
    flex-direction: row;
    grid-template-columns: 1fr 0.5fr;
  }

  & svg {
    color: $tokenColorsBrandDefault;
  }

  & img {
    height: auto;
    margin: 0 auto;
    margin-bottom: -$tokenSpacingDefault;
    object-fit: cover;
    position: relative;
    width: calc(($tokenSpacingLarge8X * 2.5));

    @media (--breakpointMediumFrom) {
      margin-bottom: -$tokenFontSizesLarge4X;
      width: calc(($tokenSpacingLarge8X * 4) + $tokenSpacingLarge2X);
    }

    @media (--breakpointLargeFrom) {
      margin-bottom: 0;
      margin-left: -$tokenSpacingLarge6X;
    }
  }

  & .contentWrapper {
    --margin: 0;

    background-color: $tokenColorsBrandTertiaryDefault;
    border-radius: $tokenSpacingLarge2X;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingLarge4X;
    margin: var(--margin);
    padding: $tokenSpacingLarge3X $tokenSpacingLarge1X;
    padding-top: $tokenSpacingLarge3X;

    @media (--breakpointLargeFrom) {
      --margin: 0;

      gap: $tokenSpacingLarge4X;
      padding: $tokenSpacingLarge5X;
      padding-right: $tokenSpacingLarge9X;
    }

    & .mainContent {
      display: flex;
      flex-direction: column;
      gap: $tokenSpacingDefault;

      & .description {
        font-size: $tokenFontSizesSmall1X;

        @media (--breakpointLargeFrom) {
          font-size: $tokenFontSizesDefault;
        }

        & span {
          color: $tokenColorsBrandDefault;
        }
      }

      & .headingWrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: $tokenSpacingSmall2X;

        & svg {
          min-height: $tokenSpacingLarge4X;
          min-width: $tokenSpacingLarge4X;
        }

        & h2 {
          text-align: left;
        }

        & .accidentCoverTitleContainer {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: $tokenSpacingSmall3X;
        }
      }
    }

    & .accidentCovers {
      display: flex;
      flex-direction: column;
      gap: $tokenSpacingLarge1X;

      @media (--breakpointLargeFrom) {
        flex-direction: row;
        gap: $tokenSpacingLarge4X;
      }

      & .accidentCoverWrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $tokenSpacingSmall2X;
        text-align: left;

        @media (--breakpointLargeFrom) {
          flex-direction: column;
          gap: 0;
          text-align: center;
        }

        & .accidentCover {
          font-size: $tokenFontSizesSmall1X;

          @media (--breakpointMediumFrom) {
            font-size: $tokenFontSizesDefault;
          }
        }

        & .accidentCoverTitle {
          font-size: $tokenFontSizesDefault;
          font-weight: $tokenFontWeightsBold;

          @media (--breakpointMediumFrom) {
            font-size: $tokenFontSizesLarge1X;
          }
        }
      }
    }
  }
}
